import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import HowItWorksImg from '../assets/img/howitworks/how_it_works_image.png';

const useStyles = makeStyles(theme => ({
  sectionWrapper: {
    padding: theme.spacing(1),
    backgroundColor: '#f9f9f9',
    textAlign: 'center'
  },
  header: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2)
  },
  subHeader: {
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: '1.2rem'
    }
  },
  howItWorksImg: {
    maxWidth: '80%',
    maxHeight: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  }
}));

const HowItWorks = () => {
  const classes = useStyles();

  return (
    <div className={classes.sectionWrapper}>
      <h2 style={{ fontWeight: 'bold' }}>How Sub-X Works</h2>
      <Typography variant="h3" className={classes.subHeader}>
        4 Easy steps and you're ready to go!
      </Typography>
      <Grid container>
        <Grid item xs={12} md={12} sm={12}>
          <img
            id="howItworksImg"
            src={HowItWorksImg}
            className={classes.howItWorksImg}
            alt=""
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default HowItWorks;
