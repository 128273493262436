import React from 'react';
import About_left_shape from '../assets/img/about/about-left-shape.svg';
import About_dots from '../assets/img/about/left-dots.svg';
import AboutImage1 from '../assets/img/about/create_a_workout_web.png';
import AboutImage2 from '../assets/img/about/dashboard_web.png';
const AboutSection = () => {
  return (
    <div>
      <section id="about" className="about-section pt-30 ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="about-img">
                <img src={AboutImage1} alt="" className="w-100" />
                <img src={About_left_shape} alt="" className="shape shape-1" />
                <img src={About_dots} alt="" className="shape shape-2" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="about-content">
                <div className="section-title mb-30">
                  <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                    Welcome to Sub-X, the ultimate solution for group trainers!
                  </h2>
                  <p className="wow fadeInUp" data-wow-delay=".4s">
                    Our revolutionary application empowers trainers to elevate
                    their workout planning like never before. With Sub-X,
                    trainers can say goodbye to manual pace calculations and
                    embrace the power of automation.
                  </p>
                  <p className="wow fadeInUp" data-wow-delay=".4s">
                    Our cutting-edge technology enables automatic individual
                    pace setting for each trainee in the group, tailoring
                    workouts to their specific abilities and goals.
                  </p>
                  <p className="wow fadeInUp" data-wow-delay=".4s">
                    Say hello to efficiency and personalized training like never
                    before.
                  </p>
                  <p className="wow fadeInUp" data-wow-delay=".4s">
                    Sub-X, your pathway to fitness success!
                  </p>
                </div>
                {/* <a
                  href="#"
                  className="main-btn btn-hover border-btn wow fadeInUp"
                  data-wow-delay=".6s">
                  Discover More
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="about-section pt-30">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="about-content">
                <div className="section-title mb-30">
                  <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                    The ultimate companion for group trainers!
                  </h2>
                  <p className="wow fadeInUp" data-wow-delay=".4s">
                    Enhance your workout planning efficiency with our powerful
                    application. Monitor the actual performance of trainees in a
                    single pane of glass.
                  </p>
                </div>
                <ul>
                  <li>
                    Track trainees' progress and performance instantly, making
                    informed adjustments to optimize their training
                  </li>
                  <li>No need for juggling multiple tools or spreadsheets</li>
                  <li>
                    Tailor individual guidance and feedback based on performance
                    metrics, ensuring each trainee reaches their full potential
                  </li>
                </ul>
                {/* <a
                  href="#"
                  className="main-btn btn-hover border-btn wow fadeInUp"
                  data-wow-delay=".6s">
                  Learn More
                </a> */}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 order-first order-lg-last">
              <div className="about-img-2">
                <img src={AboutImage2} alt="" className="w-100" />
                <img
                  src="assets/img/about/about-right-shape.svg"
                  alt=""
                  className="shape shape-1"
                />
                <img
                  src="assets/img/about/right-dots.svg"
                  alt=""
                  className="shape shape-2"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutSection;
