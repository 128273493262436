import React from 'react';
import { Grid } from '@material-ui/core';

const Footer = () => {
  return (
    <div>
      <footer className="footer mt-0">
        <div className="container">
          <div className="widget-wrapper">
            <Grid container spacing={2}>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                <div className="footer-widget">
                  <div className="logo mb-30">
                    <a href="index.html">
                      <img src="assets/img/logo/logo.svg" alt="" />
                    </a>
                  </div>
                  <p className="desc mb-30 text-white">
                    Save time and focus on what matters most. Harness the power
                    of technology to personalize and optimize your training
                    plans.
                  </p>
                  <ul className="socials">
                    <li>
                      <a href="#">
                        <i className="lni lni-facebook-filled"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="lni lni-twitter-filled"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="lni lni-instagram-filled"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="lni lni-linkedin-original"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid item xl={3} md={3} sm={12} xs={12}>
                <div className="footer-widget">
                  <h3>About Us</h3>
                  <ul className="links">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="#benefits">Benefits</a>
                    </li>
                    <li>
                      <a href="#about">About</a>
                    </li>
                    <li>
                      <a href="#testimonials">Testimonials</a>
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid item xl={3} md={3} sm={12} xs={12}>
                <div className="footer-widget">
                  <h3>Features</h3>
                  <ul className="links">
                    <li>
                      <a href="#about">How it works</a>
                    </li>
                    <li>
                      <a href="/privacy">Privacy policy</a>
                    </li>
                    <li>
                      <a href="#pricing">Pricing</a>
                    </li>
                    <li>
                      <a href="/sign-up">Sign-Up</a>
                    </li>
                  </ul>
                </div>
              </Grid>
              {/* <Grid item xs={3} md={3} sm={12}> */}
              {/* <div className="footer-widget">
                  <h3>Other Products</h3>
                  <ul className="links">
                    <li>
                      <a href="#">Accounting Software</a>
                    </li>
                    <li>
                      <a href="#">Billing Software</a>
                    </li>
                    <li>
                      <a href="#">Booking System</a>
                    </li>
                    <li>
                      <a href="#">Tracking System</a>
                    </li>
                  </ul>
                </div> */}
              {/* </Grid> */}
            </Grid>
          </div>
        </div>
      </footer>
      <a href="#" className="scroll-top btn-hover">
        <i className="lni lni-chevron-up"></i>
      </a>
    </div>
  );
};

export default Footer;
