import React from 'react';
import HeroSectImg from '../assets/img/hero/hero-image.png';
import Fade from '@material-ui/core/Fade';

const HeroSection = () => {
  return (
    <Fade in={true} timeout={1000}>
      <section
        id="home"
        className="hero-section"
        style={{ paddingTop: '70px' }}>
        <div className="container">
          <div className="row align-items-center position-relative">
            <div className="col-lg-6">
              <div className="hero-content">
                <div
                  style={{
                    color: 'white',
                    fontSize: '2.8rem'
                    // fontWeight: 'bold'
                  }}
                  className="wow fadeInUp"
                  data-wow-delay=".4s">
                  Say Goodbye to&nbsp;
                  <span style={{ color: '#FF7E76' }}>Spreadsheets</span>&nbsp;
                  <br />
                  Focus on Coaching,&nbsp;
                  <br />
                  <span style={{ color: '#FF7E76' }}>Not Admin</span>
                </div>

                <p className="wow fadeInUp" data-wow-delay=".6s">
                  All-in-one platform for running coaches to set paces and
                  goals, track progress, and communicate effortlessly – so
                  &nbsp;
                  <span style={{ color: '#FF7E76', fontWeight: 'bold' }}>
                    you can focus on coaching, not admin.
                  </span>
                </p>
                <a
                  className="main-btn border-btn btn-hover wow fadeInUp"
                  data-wow-delay={'1s'}
                  href="/sign-up">
                  Start My Free Trial
                </a>
                <div
                  id="hero-signup-subtext"
                  style={{
                    fontSize: '0.9rem',
                    // color: 'white',
                    marginTop: '3px',
                    fontStyle: 'italic'
                  }}>
                  30 days free with unlimited clients. No credit card required.
                </div>
                {/* <a href="#benefits" className="scroll-bottom">
                  <i className="lni lni-arrow-down"></i>
                </a> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-img wow fadeInUp" data-wow-delay=".5s">
                <img
                  id="hero-image"
                  src={HeroSectImg}
                  style={{ maxWidth: '833px', maxHeight: '614px' }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fade>
  );
};

export default HeroSection;
