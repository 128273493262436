import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  sectionWrapper: {
    padding: theme.spacing(1),
    backgroundColor: '#f9f9f9',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    }
  },
  header: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2)
  },
  subHeader: {
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      // resize font size to fit smaller screens
      fontSize: '1.2rem'
    }
  },
  card: {
    backgroundColor: '#576AF6',
    color: '#e0e0e0',
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: '#f9f9f9',
      color: 'black',
      border: '1px solid black'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(1)
    }
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    // marginLeft: theme.spacing(2),
    color: 'inherit'
  },
  cardTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    color: 'inherit',
    width: '25%',
    // should be vertically centered
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem'
    }
  },
  cardDescription: {
    lineHeight: 1.5,
    textAlign: 'left',
    color: 'inherit',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
      lineHeight: 1.1,
      paddingLeft: theme.spacing(2)
    }
    // color: '#b0b0b0'
  },
  findOutMore: {
    color: '#fbbc05',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    alignSelf: 'flex-start',
    textTransform: 'none',
    '&:hover': {
      color: 'black'
    }
  }
}));

const JoinTheRevolution = () => {
  const classes = useStyles();

  return (
    <div className={classes.sectionWrapper}>
      <h2 style={{ fontWeight: 'bold' }}>Join the revolution.</h2>
      <Typography variant="h3" className={classes.subHeader}>
        Empowering running coaches and fitness trainers to streamline their
        training and connect with their athletes through Sub-X.
      </Typography>
      <Grid container>
        <Grid item xs={12} md={12}>
          <div className={classes.card}>
            <Typography variant="h3" className={classes.cardTitle}>
              Running Group Trainers
            </Typography>
            <div className={classes.cardContent}>
              <Typography variant="h4" className={classes.cardDescription}>
                For coaches managing running groups who want to simplify their
                planning, communication, and pace setting. Sub-X helps you
                effortlessly set individual and group goals, track progress, and
                create dynamic, personalized plans for each runner — allowing
                you to focus more on coaching and less on admin.
              </Typography>
              {/* <Button className={classes.findOutMore}>FIND OUT MORE</Button> */}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className={classes.card}>
            <Typography variant="h3" className={classes.cardTitle}>
              Online Remote Trainers
            </Typography>
            <div className={classes.cardContent}>
              <Typography variant="h4" className={classes.cardDescription}>
                For remote trainers who want to stay engaged with clients from a
                distance. Sub-X provides powerful tools to plan, monitor, and
                adjust training programs based on your athletes’ progress,
                wherever they are. Build stronger client relationships without
                the need for multiple tools.
              </Typography>
              {/* <Button className={classes.findOutMore}>FIND OUT MORE</Button> */}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className={classes.card}>
            <Typography variant="h3" className={classes.cardTitle}>
              Enterprise & <br /> Workplace Workouts
            </Typography>
            <div className={classes.cardContent}>
              <Typography variant="h4" className={classes.cardDescription}>
                For corporate wellness programs and large organizations looking
                to integrate running and fitness into their employee wellness
                initiatives. Sub-X offers scalable solutions to manage and
                monitor fitness programs, promoting health and productivity
                within your workforce.
              </Typography>
              {/* <Button className={classes.findOutMore}>FIND OUT MORE</Button> */}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default JoinTheRevolution;
